<template>
  <v-container fluid class="px-5">
    <v-row>
      <v-col cols="12" sm="8" lg="9" class="text-left">
        <v-card flat>
          <v-card-title class="mb-0 pb-1">
            Соревнования и хакатоны
          </v-card-title>
          <v-card-text>
            Воплощай свои идеи в жизнь: участвуй, обменивайся опытом, побеждай, выигрывай призы!
          </v-card-text>
        </v-card>
        <v-sheet flat class="mb-6 font-weight-light">
          
        </v-sheet>
        <EventCard
          v-for="event in searchedList"
          :key="event.event_id"
          :event="event"/>
      </v-col>
      <v-col cols="12" sm="4" lg="3" class="text-left">
        <v-card flat>
          <v-card-text>
            <v-text-field
            v-model="searchField"
            rounded
            dense
            hide-details
            filled
            :rules="searchError"
            label="Поиск"  
            class="form-input teal--text darken-3"
            color="teal darken-3"
            background-color="teal lighten-5"
            required></v-text-field>
            <v-checkbox v-model="challengeCheck" :disabled="hackathonCheck" color="teal darken-3" class="mb-1" hide-details>
              <template v-slot:label>
                    <div class="font-weight-regular text-caption">
                      Соревнование
                    </div>
                  </template>
            </v-checkbox>
            <v-checkbox v-model="hackathonCheck" :disabled="challengeCheck" color="teal darken-3" class="mt-0" hide-details>
              <template v-slot:label>
                    <div class="font-weight-regular text-caption">
                      Хакатон
                    </div>
                  </template>
            </v-checkbox>
            <!-- <v-checkbox v-model="activeCheck" color="teal darken-3" class="mt-0" hide-details>
              <template v-slot:label>
                    <div class="font-weight-light text-caption">
                      Только активные
                    </div>
                  </template>
            </v-checkbox> -->
          </v-card-text>
          <v-card-actions class="text-right">
            <v-spacer></v-spacer>
            <v-btn text
            @click="resetFilters"
            small
            color="teal darken-2">
              Сбросить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import EventCard from '@/components/EventCard.vue'

export default {
  name: 'HomeView',
  data: () => ({
    searchField: null,
    challengeCheck: false,
    hackathonCheck: false,
    activeCheck: false,
  }),
  components: {
    EventCard
  },
  watch: {
    searchField(newVal, oldVal) {
      this.registrationError = null
    }
  },
  computed: {
    ...mapState({
        loading: state => state.loading,
        events: state => state.event.events_short
    }),
    searchError() {
      return (!this.searchField || this.searchedList.length !== 0) || "Ничего не найдено"
    },
    noFilters() {
      return !(this.challengeCheck || this.hackathonCheck || this.activeCheck || !!this.searchField)
    },
    filteredList() {
      if (this.noFilters) {
        return this.events
      } else {
        return this.events.filter(
          (e) =>  (e.event_type.event_type === "mlchallenge") || !this.challengeCheck
        ).filter(
          (e) =>  (e.event_type.event_type === "hackaton") || !this.hackathonCheck
        )
      }
    },
    searchedList() {
      if (!this.searchField) {
        return this.filteredList
      } else {
        return this.filteredList.filter(
          (e) => e.short_name.toLowerCase().indexOf(this.searchField) !== -1
        )
      }
    }
  },
  mounted() {
    this.$store.dispatch('event/events').then(response => {},
    error => {})
  },
  methods: {
    resetFilters() {
      this.searchField = null
      this.challengeCheck = false
      this.hackathonCheck = false
      this.activeCheck = false
    }
  }
}
</script>

<style>
.challenge-card-sidebar {
  font-size: small !important;
}
.material-icons { vertical-align: middle }
.form-input {
    border-radius: 10px;
}
</style>