import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/lib/locale/ru'
import en from 'vuetify/lib/locale/en'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { ru, en},
        current: 'ru'
    }
});
