<template> 
    <v-container class="px-0 py-0 mb-6">
        <v-card outlined class="px-0 pb-3" :ripple="false" :class="flat ? 'elevation-0' : 'elevation-2'" :to="{ name: 'event', params: {id: event.id}}">
          <v-img :src="`${serverUrl}${event.img_link}`"></v-img>
          <v-card-title class="teal--text text--darken-4 px-5">
            <v-row class="ma-0 pa-0">
              <v-col cols="12" sm="6" class="ma-0 pa-0">
                {{ event.short_name }}
              </v-col>
              <v-col cols="12" sm="6" class="ma-0 pa-0 text-right">
                <v-chip v-if="currentlyRunning" small class="ml-2 white--text font-weight-bold green darken-1">
                    Идет сейчас
                </v-chip>
                <v-chip v-if="!event.is_open" small class="ml-2 white--text font-weight-bold blue-grey darken-2">
                    Закрытое
                </v-chip>
                <v-chip v-if="event.archived" small class="ml-2 white--text font-weight-bold cyan darken-2">
                    Архив
                </v-chip>
                <a v-if="event.news_link" :href="event.news_link" target="_blank" rel="noopener noreferrer">
                    <img :src="require('@/assets/icons8-telegram-24.png')" height="16" class="ml-2" style="vertical-align: middle;"/>
                </a>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-subtitle
          :class="event.event_type.event_type==='mlchallenge' ? 'teal--text text--darken-4' : 'amber--text text--darken-4'"
          class="px-5 font-weight-light">
              {{ event.event_type.short_name }}
          </v-card-subtitle>

          <v-card-text class="px-5 pb-1">
            <v-row class="pl-0 mx-0">
              <v-col cols="12" sm="2" class="ml-0 pt-0 pl-0">
                <v-list-item v-if="event.total_prize.value !== '0'" dense two-line class="px-0">
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="challenge-card-sidebar grey--text text--darken-4">Призовой фонд</v-list-item-title>
                    <v-list-item-subtitle  class="challenge-card-sidebar">
                        {{ event.total_prize.value }} {{ event.total_prize.units == "K" ? "тыс." : "млн." }}
                        <v-icon v-if="event.total_prize.currency === 'RUR'" x-small>mdi-currency-rub</v-icon>
                        <v-icon v-else x-small>mdi-currency-usd</v-icon>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                
                <!-- <v-list-item dense two-line class="px-0">
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="challenge-card-sidebar grey--text text--darken-4">Призы</v-list-item-title>
                    <v-list-item-subtitle  class="challenge-card-sidebar">
                      <v-icon small style="vertical-align: middle;" color="amber darken-1">mdi-star-box</v-icon>
                      <v-icon small color="blue-grey lighten-1">mdi-star-box</v-icon>
                      <v-icon small color="brown lighten-2">mdi-star-box</v-icon>
                      <v-icon small color="grey lighten-1">mdi-star</v-icon>
                      <v-icon small color="grey lighten-1">mdi-star</v-icon>
                      <v-icon small class="mx-2">mdi-close</v-icon>2
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item> -->
              </v-col>
              <v-col cols="12" sm="2" class="ml-0 pt-0 pl-0">
                <v-list-item dense two-line class="px-0">
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="challenge-card-sidebar grey--text text--darken-4">Даты проведения</v-list-item-title>
                    <v-list-item-subtitle class="challenge-card-sidebar">
                        {{ (new Date(event.starts_at)).toLocaleDateString('ru-RU') }} - {{ (new Date(event.ends_at)).toLocaleDateString('ru-RU') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense two-line class="px-0">
                  <v-list-item-content class="py-0">
                    <v-list-item-title>Организатор</v-list-item-title>
                    <v-list-item-subtitle>
                        <a v-if="event.client.custom?.link"
                        :href="event.client.custom?.link"
                        target="_blank"
                        @click.stop
                        rel="noopener noreferrer">
                            {{ event.client.short_name }}
                        </a>
                        <span v-else>{{ event.client.short_name }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="8" class="px-0 py-2" v-html="event.head_line_name"/>
            </v-row>
          </v-card-text>
        </v-card>
    </v-container>
</template>
  
<script>
import event from '@/store/modules/event';
import { EVENT_TYPES } from '@/modules/constants'

const serverUrl = process.env.VUE_APP_ENV_SERVER;

export default {
    name: 'EventCard',
    props: {
        event: {
            type: Object
        },
        flat: {
          type: Boolean,
          default: false
        }
    },
    data: () => ({
        serverUrl: serverUrl
    }),
    computed: {
        currentlyRunning() {
            const now = new Date()
            const starts_at = new Date(event.starts_at)
            const ends_at = new Date(event.ends_at)
            return (now >= starts_at) && (now <= ends_at)
        }
    }
}
</script>  