import axios from 'axios';
import { ERROR_CODES } from '../../modules/constants'

const apiUrl = process.env.VUE_APP_ENV_API;
const MODULE = "store/module/event"

export default {
    namespaced: true,
    state: {
        events_short: null,
        events_full: null,
        error_codes: ERROR_CODES
    },
    getters: {
    },
    mutations: {
        SET_EVENT_SHORT_LIST(state, { events }) {
            state.events_short = events
        },
        ADD_EVENT_FULL(state, { event }) {
            if (state.events_full === null) {
                state.events_full = [ event ]
            } else {
                const eventPresent = state.events_full.findIndex(({ event_id }) => event_id == event.event_id) !== -1
                if (!eventPresent) {
                    state.events_full.push(event)
                }
            }
        },
    },
    actions: {
        async events(context) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })

            return new Promise((resolve, reject) => {
                axios.get(
                    `${apiUrl}/generic/events`,
                    { withCredentials: true }
                ).then(
                    response => {
                        context.commit("SET_EVENT_SHORT_LIST", { events: response.data })
                        resolve()
                    },
                    error => {
                        let detail
                        switch (error.response.status) {
                            case 422:
                                detail = "STRUCTURE_ERROR"
                                break
                            default:
                                break
                        }
                        context.commit(
                            "SET_CURRENT_ERROR",
                            { error: detail || "UNKNOWN", source: `${MODULE}:events` },
                            { root: true }
                        )
                        reject(detail)
                    }
                )
            })
        },
        async event(context, { event_id }) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })

            return new Promise((resolve, reject) => {
                axios.get(
                    `${apiUrl}/generic/event/${event_id}`,
                    { withCredentials: true }
                ).then(
                    response => {
                        context.commit("ADD_EVENT_FULL", { event: response.data })
                        resolve(response.data)
                    },
                    error => {
                        let detail
                        switch (error.response.status) {
                            case 404:
                                detail = ERROR_CODES.EVENT_NOT_FOUND
                                break
                            case 422:
                                detail = ERROR_CODES.STRUCTURE_ERROR
                                break
                            default:
                                break
                        }
                        context.commit(
                            "SET_CURRENT_ERROR",
                            { error: detail || ERROR_CODES.UNKNOWN, source: `${MODULE}:event` },
                            { root: true }
                        )
                        reject(detail)
                    }
                )
            })
        }
    }
}