import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/auth/',
    component: () => import('../views/AuthView.vue'),
    children: [
      {
        path: "login",
        name: "login-page",
        component: () => import('../components/SignInForm.vue')
      },
      {
        path: "register",
        name: "register-page",
        component: () => import('../components/SignUpForm.vue')
      },
      {
        path: "request-reset",
        name: "request-reset-page",
        component: () => import('../components/ResetRequestForm.vue')
      },
      {
        path: "verify",
        name: "verify-page",
        component: () => import('../components/VerifyScreen.vue')
      },
      {
        path: "post",
        name: "post-page",
        component: () => import('../components/PostScreen.vue')
      },
      {
        path: "reset-password",
        name: "reset-password-page",
        component: () => import('../components/ResetPasswordForm.vue')
      }
    ]
  },
  {
    path: '/event/:id',
    name: 'event',
    component: () => import('../views/EventView.vue')
  },
  {
    path: '/registered',
    name: 'registered',
    component: () => import('../components/ProgressScreen.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
