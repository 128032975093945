<template>
  <v-app>
    <v-app-bar app absolute color="white" dense class="app-bar px-6 mx-auto elevation-0" elevation-0>
      <router-link to="/"><v-img max-width="112px" :src="require('@/assets/AIT_tl.png')" class="mx-2"></v-img></router-link>
      <v-spacer></v-spacer>
      <v-progress-circular
      v-if="loading"
      :size="25"
      :width="2"
      color="teal darken-2"
      indeterminate
      ></v-progress-circular>
      <v-btn
        v-if="this.$store.state.auth.loggedIn"
        text small
        class="mr-4 ml-4 font-weight-bold"
        color="red darken-4">
        {{ user_name }}
      </v-btn>
      <v-btn
        v-if="!this.$store.state.auth.loggedIn"
        @click="$router.push({ name: 'login-page' })"
        text small
        class="mr-4 ml-4"
        color="teal darken-2">
        Войти
      </v-btn>
      <v-btn
        v-if="this.$store.state.auth.loggedIn"
        @click="logout"
        text small
        class="mr-4"
        color="teal darken-2">
        Выйти
      </v-btn>
      <v-btn
        v-if="!this.$store.state.auth.loggedIn"
        @click="$router.push({ name: 'register-page' })"
        depressed
        small
        color="teal darken-2"
        class="white--text elevation-0">
        Зарегистрироваться
      </v-btn>
    </v-app-bar>

    <v-main class="main-container mx-lg-auto mx-sm-3">
      <v-fade-transition :hide-on-leave="true">
      <router-view/>
    </v-fade-transition>
    </v-main>

    <v-footer inset padless class="mt-6">
      <v-col
      class="teal darken-2 text-center text-caption teal--text text--lighten-5 font-weight-regular"
      cols="12">
        {{ new Date().getFullYear() }} — разработано в <span class="footer-dr">datarythmics</span>
      </v-col>
    </v-footer>

  </v-app>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AITodayMLplatform',
  data: () => ({}),
  mounted() {
    this.$store.dispatch('auth/heartbeat').then(response => {},
    error => {
      this.logout()
    })
  },
  methods: {
    logout() {
      this.$store.dispatch("switchLoading")
      this.$store.dispatch('auth/logout').then(
        response => {}, error => {}
      )
      this.$store.dispatch("switchLoading")
    }
  },
  computed: {
    ...mapState({
        loading: state => state.loading,
        user_name: state => state.auth.user_name
    })
  }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;700&display=swap');

#app {
  font-family: 'Raleway', 'Source Sans Pro', Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: small;
  color: #2c3e50;
}
.app-bar {
  max-width: 1800px;
  border-bottom: 1px solid #2c3e50 !important;
}
.main-layout {
  height: 100vh;
  padding-top: 64px;
}
.main-container {
  max-width: 1140px;
}
.footer-dr {
  font-family: 'Quicksand';
}
.app-bar-link:hover {
  color: #E53935 !important;
  background-color: white !important;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
