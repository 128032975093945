import axios from 'axios';
import { ERROR_CODES, AUTH_STAGES } from '../../modules/constants'

const apiUrl = process.env.VUE_APP_ENV_API;
const authUrl = process.env.VUE_APP_ENV_AUTH;
const usersUrl = process.env.VUE_APP_ENV_USERS;
const MODULE = "store/module/auth"

export default {
    namespaced: true,
    state: {
        user_id: null,
        email: null,
        user_name: null,
        first_name: null,
        last_name: null,
        loggedIn: false,
        defaultHeadline: "Упс... что-то пошло не так!",
        defaultMessage: "",
        error_codes: ERROR_CODES,
        auth_stages: AUTH_STAGES
    },
    getters: {
    },
    mutations: {
        SET_CURRENT_USER(state, { email, user_name, first_name, last_name }) {
            state.email = email
            state.user_name = user_name
            state.first_name = first_name
            state.last_name = last_name
        },
        UNSET_CURRENT_USER(state) {
            state.email = null
            state.user_name = null
            state.first_name = null
            state.last_name = null
        },
        SET_LOGGED_IN(state, { loggedIn }) {
            state.loggedIn = loggedIn
        }
    },
    actions: {
        async heartbeat(context) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })

            return new Promise((resolve, reject) => {
                axios.get(
                    `${usersUrl}/me`,
                    { withCredentials: true }
                ).then(
                    response => {
                        context.commit("SET_CURRENT_USER",
                        {
                            email: response.data.email,
                            user_name: response.data.user_name,
                            first_name: response.data.first_name,
                            last_name: response.data.last_name,
                        })
                        context.commit("SET_LOGGED_IN", { loggedIn: true })
                        resolve()
                    },
                    error => {
                        context.commit("SET_CURRENT_USER", { email: null })
                        context.commit("SET_LOGGED_IN", { loggedIn: false })
                        reject(error)
                    }
                )
            })
        },
        async register(context, { email, password, user_name, first_name, last_name }) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })

            return new Promise((resolve, reject) => {
                axios.post(
                    `${authUrl}/register`,
                    { email, password, user_name, first_name, last_name }
                ).then(
                    response => {
                        if (response.status == 201) {
                            context.commit("SET_CURRENT_USER", {
                                email: response.data.email
                            })
                            context.commit("SET_LOGGED_IN", { loggedIn: false })
                        }
                        resolve()
                    },
                    error => {
                        let detail = "UNKNOWN"
                        switch (error.response.status) {
                            case 400:
                                detail = error.response.data?.detail?.code || error.response.data?.detail
                                break
                            case 422:
                                detail = "STRUCTURE_ERROR"
                                break
                            default:
                                break
                        }
                        context.commit(
                            "SET_CURRENT_ERROR",
                            { error: detail || "UNKNOWN", source: `${MODULE}:register` },
                            { root: true }
                        )
                        reject(detail)
                    }
                )
            })
        },
        async requestVerify(context, { email }) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })

            return new Promise((resolve, reject) => {
                axios.post(
                    `${authUrl}/request-verify-token`,
                    { email }
                ).then(
                    response => {
                        resolve()
                    },
                    error => {
                        reject(error)
                    }
                )
            })
        },
        async verify(context, { token }) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })

            return new Promise((resolve, reject) => {
                axios.post(
                    `${authUrl}/verify`,
                    { token }
                ).then(
                    response => {
                        resolve()
                    },
                    error => {
                        let detail = "UNKNOWN"
                        switch (error.response.status) {
                            case 400:
                                detail = error.response.data.detail
                                break
                            case 422:
                                detail = "STRUCTURE_ERROR"
                                break
                            default:
                                break
                        }
                        context.commit(
                            "SET_CURRENT_ERROR",
                            { error: detail || "UNKNOWN", source: `${MODULE}:register` },
                            { root: true }
                        )
                        reject(detail)
                    }
                )
            })
        },
        async login(context, { email, password }) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })

            const params = new URLSearchParams();
            params.append("username", email);
            params.append("password", password);

            return new Promise((resolve, reject) => {
                axios.post(
                    `${authUrl}/jwt/login`,
                    params,
                    { withCredentials: true }
                ).then(
                    response => {
                        context.commit("SET_CURRENT_USER",
                        {
                            email: response.data.email,
                            user_name: response.data.user_name,
                            first_name: response.data.first_name,
                            last_name: response.data.last_name,
                        })
                        context.commit("SET_LOGGED_IN", { loggedIn: true })
                        resolve(email)
                    },
                    error => {
                        let detail = "UNKNOWN"
                        switch (error.response.status) {
                            case 400:
                                detail = error.response.data.detail
                                break
                            case 422:
                                detail = "STRUCTURE_ERROR"
                                break
                            default:
                                break
                        }
                        context.commit(
                            "SET_CURRENT_ERROR",
                            { error: detail || "UNKNOWN", source: `${MODULE}:register` },
                            { root: true }
                        )
                        reject(detail)
                    }
                )
            })
        },
        async logout(context) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })

            return new Promise((resolve, reject) => {
                axios.post(
                    `${authUrl}/jwt/logout`,
                    { withCredentials: true}
                ).then(
                    response => {
                        context.commit("UNSET_CURRENT_USER")
                        context.commit("SET_LOGGED_IN", { loggedIn: false })
                        resolve()
                    },
                    error => {
                        let detail = "UNKNOWN"
                        switch (error.response.status) {
                            case 401:
                                detail = "UNAUTHORIZED"
                                break
                            default:
                                break
                        }
                        context.commit("UNSET_CURRENT_USER")
                        context.commit("SET_LOGGED_IN", { loggedIn: false })
                        context.commit(
                            "SET_CURRENT_ERROR",
                            { error: detail || "UNKNOWN", source: `${MODULE}:logout` },
                            { root: true }
                        )
                        reject(detail)
                    }
                )
            })
        },
        async requestReset(context, { email }) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })

            return new Promise((resolve, reject) => {
                axios.post(
                    `${authUrl}/forgot-password`,
                    { email }
                ).then(
                    response => {
                        resolve()
                    },
                    error => {
                        reject(error)
                    }
                )
            })
        },
        async reset(context, { token, password }) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })

            return new Promise((resolve, reject) => {
                axios.post(
                    `${authUrl}/reset-password`,
                    { token, password }
                ).then(
                    response => {
                        resolve()
                    },
                    error => {
                        let detail = "UNKNOWN"
                        switch (error.response.status) {
                            case 400:
                                detail = error.response.data?.detail?.code || error.response.data?.detail
                                break
                            case 422:
                                detail = "STRUCTURE_ERROR"
                                break
                            default:
                                break
                        }
                        context.commit(
                            "SET_CURRENT_ERROR",
                            { error: detail || "UNKNOWN", source: `${MODULE}:register` },
                            { root: true }
                        )
                        reject(detail)
                    }
                )
            })
        }
    }
}