import axios from 'axios';
import { ERROR_CODES } from '../../modules/constants'

const apiUrl = process.env.VUE_APP_ENV_API;
const MODULE = "store/module/submit"

export default {
    namespaced: true,
    state: {
        submits: [],
        submits_count: [],
        team_submits: [],
        leaderboard: [],
        error_codes: ERROR_CODES,
        uploadProgress: null,
        uploading: false
    },
    getters: {
    },
    mutations: {
        SET_LB(state, { task_id, leaderboard }) {
            state.leaderboard = state.leaderboard.filter((e) => e.task_id != task_id)
            state.leaderboard.push(
                {
                    task_id,
                    leaderboard,
                    is_private: leaderboard.map((e) => e.private_metrics !== null).every(e => e === true)
                }
            )
        },
        SET_SUBMITS(state, { task_id, submits }) {
            state.submits = state.submits.filter((e) => e.task_id != task_id)
            state.submits.push(
                {
                  task_id,
                  submits,
                  is_private: submits.filter((e) => e.is_success).map((e) => e.private_metrics !== null).every(e => e === true)  
                }
            )
        },
        SET_TEAM_SUBMITS(state, { task_id, submits }) {
            state.team_submits = state.team_submits.filter((e) => e.task_id != task_id)
            state.team_submits.push(
                {
                    task_id,
                    submits,
                    is_private: submits.filter((e) => e.is_success).map((e) => e.private_metrics !== null).every(e => e === true)  
                }
            )
        },
        SET_SUBMIT_COUNT(state, { task_id, submits_left }) {
            state.submits_count = state.submits_count.filter((e) => e.task_id != task_id)
            state.submits_count.push({ task_id, submits_left})
        },
        RESET_SUBMITS(state) {
            state.submits = {}
        },
        SWITCH_UPLOADING(state) {
            state.uploading = !state.uploading
            state.uploadProgress = null
        },
        SET_UPLOAD_PROGRESS(state, { progress }) {
            state.uploadProgress = progress
        }
    },
    actions: {
        async today(context, { event_id, task_id }) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })

            return new Promise((resolve, reject) => {
                axios.get(
                    `${apiUrl}/submits/${event_id}/${task_id}/today`,
                    { withCredentials: true }).then(
                        response => {
                            context.commit("SET_SUBMIT_COUNT", { task_id, submits_left: response.data.submits_left })
                            resolve()
                    },
                    error => {
                        let detail
                        switch (error.response.status) {
                            case 422:
                                detail = "STRUCTURE_ERROR"
                                break
                            default:
                                detail = error.response.data?.detail
                                break
                        }
                        context.commit(
                            "SET_CURRENT_ERROR",
                            { error: detail || "UNKNOWN", source: `${MODULE}:today` },
                            { root: true }
                        )
                        reject(detail)
                    }
                )
            })
        },
        async upload(context, { submit_file, event_id, task_id }) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })
            context.commit({type: "SWITCH_UPLOADING"})

            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: function(progressEvent) {
                        context.commit("SET_UPLOAD_PROGRESS", {
                            progress: Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        })
                    },
                    withCredentials: true
                }
                let formData = new FormData();
                formData.append('submit_file', submit_file)

                axios.post(
                    `${apiUrl}/submits/${event_id}/${task_id}`,
                    formData, config
                ).then(
                    (response) => {
                        context.commit("SET_UPLOAD_PROGRESS", { progress: 100 })
                        context.commit("SWITCH_UPLOADING")
                        console.log("Finished the action")
                        resolve("UPLOADED")
                    },
                    (error) => {
                        let detail
                        switch (error.response.status) {
                            case 422:
                                detail = "STRUCTURE_ERROR"
                                break
                            default:
                                detail = error.response.data?.detail
                                break
                        }
                        context.commit(
                            "SET_CURRENT_ERROR",
                            { error: detail || "UNKNOWN", source: `${MODULE}:upload` },
                            { root: true }
                        )
                        context.commit("SWITCH_UPLOADING")
                        reject(detail)
                    }
                )
            })
        },
        async select(context, { submit_id, event_id, task_id }) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })

            return new Promise((resolve, reject) => {                    
                axios.patch(
                    `${apiUrl}/submits/${event_id}/${task_id}/${submit_id}/select`,
                    {}, { withCredentials: true }
                ).then(
                    (response) => {
                        resolve("SELECTED")
                    },
                    (error) => {
                        let detail
                        switch (error.response.status) {
                            case 422:
                                detail = "STRUCTURE_ERROR"
                                break
                            default:
                                detail = error.response.data?.detail
                                break
                        }
                        context.commit(
                            "SET_CURRENT_ERROR",
                            { error: detail || "UNKNOWN", source: `${MODULE}:select` },
                            { root: true }
                        )
                        reject(detail)
                    }
                )
            })
        },
        async unselect(context, { submit_id, event_id, task_id }) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })

            return new Promise((resolve, reject) => {                    
                axios.patch(
                    `${apiUrl}/submits/${event_id}/${task_id}/${submit_id}/unselect`,
                    {}, { withCredentials: true }
                ).then(
                    (response) => {
                        resolve("UNSELECTED")
                    },
                    (error) => {
                        let detail
                        switch (error.response.status) {
                            case 422:
                                detail = "STRUCTURE_ERROR"
                                break
                            default:
                                detail = error.response.data?.detail
                                break
                        }
                        context.commit(
                            "SET_CURRENT_ERROR",
                            { error: detail || "UNKNOWN", source: `${MODULE}:select` },
                            { root: true }
                        )
                        reject(detail)
                    }
                )
            })
        },
        async reset(context) {
            context.commit("RESET_SUBMITS")
        },
        async all(context, { event_id, task_id }) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })

            return new Promise((resolve, reject) => {
                axios.get(
                    `${apiUrl}/submits/${event_id}/${task_id}`,
                    { withCredentials: true }).then(
                    response => {
                        context.commit("SET_SUBMITS", { task_id, submits: response.data })
                        resolve()
                    },
                    error => {
                        let detail
                        switch (error.response.status) {
                            case 422:
                                detail = "STRUCTURE_ERROR"
                                break
                            default:
                                detail = error.response.data?.detail
                                break
                        }
                        context.commit(
                            "SET_CURRENT_ERROR",
                            { error: detail || "UNKNOWN", source: `${MODULE}:all` },
                            { root: true }
                        )
                        reject(detail)
                    }
                )
            })
        },
        async team(context, { event_id, task_id }) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })

            return new Promise((resolve, reject) => {
                axios.get(
                    `${apiUrl}/submits/${event_id}/${task_id}/team`,
                    { withCredentials: true }).then(
                    response => {
                        context.commit("SET_TEAM_SUBMITS", { task_id, submits: response.data })
                        resolve()
                    },
                    error => {
                        let detail
                        switch (error.response.status) {
                            case 422:
                                detail = "STRUCTURE_ERROR"
                                break
                            default:
                                detail = error.response.data?.detail
                                break
                        }
                        context.commit(
                            "SET_CURRENT_ERROR",
                            { error: detail || "UNKNOWN", source: `${MODULE}:team` },
                            { root: true }
                        )
                        reject(detail)
                    }
                )
            })
        },
        async leaderboard(context, { event_id, task_id }) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })

            return new Promise((resolve, reject) => {
                axios.get(
                    `${apiUrl}/submits/lb/${event_id}/${task_id}`).then(
                    response => {
                        context.commit("SET_LB", { task_id, leaderboard: response.data })
                        resolve()
                    },
                    error => {
                        let detail
                        switch (error.response.status) {
                            case 422:
                                detail = "STRUCTURE_ERROR"
                                break
                            default:
                                detail = error.response.data?.detail
                                break
                        }
                        context.commit(
                            "SET_CURRENT_ERROR",
                            { error: detail || "UNKNOWN", source: `${MODULE}:leaderboard` },
                            { root: true }
                        )
                        reject(detail)
                    }
                )
            })
        }
    }
}