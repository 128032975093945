import axios from 'axios';
import { ERROR_CODES } from '../../modules/constants'

const apiUrl = process.env.VUE_APP_ENV_API;
const MODULE = "store/module/team"

export default {
    namespaced: true,
    state: {
        teams: [],
        submits_count: [],
        error_codes: ERROR_CODES
    },
    getters: {
    },
    mutations: {
        SET_TEAM(state, { task_id, team }) {
            state.teams = state.teams.filter((e) => e.task_id != task_id)
            state.teams.push({ task_id, team})
        }
    },
    actions: {
        async team(context, { event_id, task_id }) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })

            return new Promise((resolve, reject) => {
                axios.get(
                    `${apiUrl}/teams/${event_id}/${task_id}`,
                    { withCredentials: true }).then(
                        response => {
                            console.log(response)
                            context.commit("SET_TEAM", { task_id, team: response.data })
                            resolve()
                        },
                        error => {
                            let detail
                            switch (error.response.status) {
                                case 404:
                                    detail = "NO_TEAM"
                                    break
                                case 422:
                                    detail = "STRUCTURE_ERROR"
                                    break
                                default:
                                    detail = error.response.data?.detail
                                    break
                            }
                            context.commit(
                                "SET_CURRENT_ERROR",
                                { error: detail || "UNKNOWN", source: `${MODULE}:team` },
                                { root: true }
                            )
                            reject(detail)
                        }
                    )
                })
        },
        async create(context, { short_name, descr, event_id, task_id }) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })

            return new Promise((resolve, reject) => {
                const config = {
                    withCredentials: true
                }

                axios.post(
                    `${apiUrl}/teams/${event_id}/${task_id}`,
                    { short_name, descr },
                    config
                ).then(
                    (response) => {
                        context.commit("SET_TEAM", { task_id, team: response.data })
                        resolve()
                    },
                    (error) => {
                        let detail
                        switch (error.response.status) {
                            case 422:
                                detail = "STRUCTURE_ERROR"
                                break
                            default:
                                detail = error.response.data?.detail
                                break
                        }
                        context.commit(
                            "SET_CURRENT_ERROR",
                            { error: detail || "UNKNOWN", source: `${MODULE}:upload` },
                            { root: true }
                        )
                        reject(detail)
                    }
                )
            })
        },
        async join(context, { team_key }) {
            context.commit({type: "RESET_CURRENT_ERROR"}, { root: true })

            return new Promise((resolve, reject) => {
                axios.put(
                    `${apiUrl}/teams/join/${team_key}`,
                    {},
                    { withCredentials: true }
                ).then(
                    (response) => {
                        context.commit("SET_TEAM", { task_id: response.data.task_id, team: response.data })
                        resolve()
                    },
                    (error) => {
                        let detail
                        switch (error.response.status) {
                            case 422:
                                detail = "STRUCTURE_ERROR"
                                break
                            default:
                                detail = error.response.data?.detail
                                break
                        }
                        context.commit(
                            "SET_CURRENT_ERROR",
                            { error: detail || "UNKNOWN", source: `${MODULE}:upload` },
                            { root: true }
                        )
                        reject(detail)
                    }
                )
            })
        }
    }
}