import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./modules/auth"
import event from "./modules/event"
import submit from "./modules/submit"
import team from "./modules/team"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    current_error: null,
    current_error_source: null,
    loading: false
  },
  getters: {
  },
  mutations: {
    SET_CURRENT_ERROR(state, { error, source}){
      state.current_error = error
      state.current_error_source = source
    },
    RESET_CURRENT_ERROR(state){
      state.current_error = null
      state.current_error_source = null
    },
    SWITCH_LOADING(state){
      state.loading = !state.loading
    }
  },
  actions: {
    switchLoading(context) {
      context.commit("SWITCH_LOADING")
    },
    resetError(context) {
      context.commit("RESET_CURRENT_ERROR")
    }
  },
  modules: {
    auth,
    event,
    submit,
    team
  }
})
