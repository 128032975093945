export const ERROR_CODES = {
    LOGIN_BAD_CREDENTIALS: "LOGIN_BAD_CREDENTIALS",
    LOGIN_USER_NOT_VERIFIED: "LOGIN_USER_NOT_VERIFIED",
    REGISTER_USER_ALREADY_EXISTS: "REGISTER_USER_ALREADY_EXISTS",
    REGISTER_USERNAME_ALREADY_EXISTS: "REGISTER_USERNAME_ALREADY_EXISTS",
    REGISTER_INVALID_PASSWORD: "REGISTER_INVALID_PASSWORD",
    RESET_PASSWORD_BAD_TOKEN: "RESET_PASSWORD_BAD_TOKEN",
    RESET_PASSWORD_INVALID_PASSWORD: "RESET_PASSWORD_INVALID_PASSWORD",
    VERIFY_USER_BAD_TOKEN: "VERIFY_USER_BAD_TOKEN",
    VERIFY_USER_ALREADY_VERIFIED: "VERIFY_USER_ALREADY_VERIFIED",
    EVENT_NOT_FOUND: "EVENT_NOT_FOUND",
    UNKNOWN: "UNKNOWN",
    STRUCTURE_ERROR: "STRUCTURE_ERROR",
    EVENT_NOT_ACTIVE: "EVENT_NOT_ACTIVE",
    UNKNOWN_SUBMIT: "UNKNOWN_SUBMIT",
    MAX_SUBMITS_REACHED: "MAX_SUBMITS_REACHED",
    SUBMIT_LOAD_FAILED: "SUBMIT_LOAD_FAILED",
    UNKNOWN_TEAM: "UNKNOWN_TEAM",
    TEAM_DEADLINE: "TEAM_DEADLINE",
    TEAM_MAX_USERS: "TEAM_MAX_USERS",
    TEAM_MAX_SUBMITS: "TEAM_MAX_SUBMITS",
    USER_HAS_TEAM: "USER_HAS_TEAM",
    TEAM_EXISTS: "TEAM_EXISTS",
    TEAM_CREATION_FAILED: "TEAM_CREATION_FAILED",
    FOREIGN_SUBMIT: "FOREIGN_SUBMIT",
    MAX_SELECTED_SUBMITS: "MAX_SELECTED_SUBMITS"
}

export const AUTH_STAGES = {
    REGISTER_SUCCESS: {
        name: "registerSuccess",
        headline: 'Спасибо за регистрацию!',
        msg:
        `<p>Мы отправили вам письмо для подтверждения email. Перейдите по ссылке в письме.</p>
        Если вы не видите письмо в папке "Входящие":
        <ul>
            <li> проверьте папку "Спам" - очень редко, но письма туда попадают,</li>
            <li> если письмо всё же не пришло - вы сможете запросить его при следующем логине.</li>
        </ul>
        <p>Вы также можете <a target='_blank' href="mailto:platform@aitoday.ru?subject=Не пришло письмо для верификации">написать</a> нам.</p>`
    },
    EMAIL_RQ_SUCCESS: {
        name: "emailRequestSuccess",
        headline: 'Подтвердите свой email',
        msg:
        `<p>Мы отправили вам письмо для подтверждения email. Перейдите по ссылке в письме.</p>
        Если вы не видите письмо в папке "Входящие":
        <ul>
            <li> проверьте папку "Спам" - очень редко, но письма туда попадают,</li>
            <li> если письмо всё же не пришло - вы сможете запросить его при следующем логине.</li>
        </ul>
        <p>Вы также можете <a target='_blank' href="mailto:platform@aitoday.ru?subject=Не пришло письмо для верификации">написать</a> нам.</p>`
    },
    EMAIL_RQ_FAILURE: {
        name: "emailRequestFailure",
        headline: 'Ошибка...',
        msg: 'Мы не смогли отправить вам письмо для потверждения email. Лучше попробовать позже.'
    },
    REGISTER_FAILURE: {
        name: "registerFailure",
        headline: 'Ошибка...',
        msg: 'Мы не смогли отправить вам письмо для потверждения email. Лучше попробовать позже.'
    },
    EMAIL_VERIFY_SUCCESS: {
        name: "emailVerifySuccess",
        headline: 'Email подтвержден'
    },
    EMAIL_VERIFY_FAILURE: {
        name: "emailVerifyFailure",
        headline: 'Ссылка недействительна',
        msg:
        `Ссылка для проверки почты действует 60 минут. Вероятно, ваша уже устарела. Запросите новую при следующем логине.`
    },
    RESET_RQ_SUCCESS: {
        name: "resetRequestSuccess",
        headline: 'Проверьте почту',
        msg:
        `Отправили письмо со ссылкой для сброса пароля. Ссылка действует 10 минут.`
    },
    RESET_RQ_FAILURE: {
        name: "resetRequestFailure",
        headline: 'Что-то пошло не так',
        msg:
        `Не смогли отправить письмо со ссылкой для сброса пароля. Попробуйте позже или <a target='_blank' href="mailto:platform@aitoday.ru?subject=Не смог сбросить пароль">напишите нам</a>.`
    },
    RESET_SUCCESS: {
        name: "resetSuccess",
        headline: 'Пароль изменён',
    },
    RESET_FAILURE: {
        name: "resetFailure",
        headline: 'Ссылка недействительна',
        msg:
        `Ссылка для проверки почты действует 10 минут. Вероятно, ваша уже устарела. Запросите новую.`
    }
}

export const EVENT_TYPES = {
    mlchallenge: {
        name: "Соревнование по машинному обучению"
    },
    hackathon: {
        name: "Хакатон"
    }
}